import { Component, Renderer2 } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
declare const  mobileMenu: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Kalgan';
  previousUrl: string;
  constructor(private renderer: Renderer2, private router: Router) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          const currentUrlSlug = event.url.slice(1);
          if (currentUrlSlug) {
            this.renderer.addClass(document.body, "k-detail-screen");
          } else {
            this.renderer.removeClass(document.body, "k-detail-screen");
          }
          this.previousUrl = currentUrlSlug;
        }
      });


  }

  toggleMenu(){
    mobileMenu();
  }
}
