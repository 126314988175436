<div class="content-base">
<div class="page-content">
    <span class="page-title">
        KALGAN&#174; Neler Yapabilir?
    </span>
    <p class="lead">
        Kötü amaçlı yazılım saldırılarının <b>56%</b>'sının arkasında Fidye yazılımı bulunmaktadır ve 2017-18'de tüm kötü amaçlı yazılım salgınları sıfır gün saldırıları olarak başladı.
    </p>
    <div class="k-features-table">
        <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" class="text-center">Antivirüs Çözümleri</th>
                <th scope="col"  class="text-center">Sanal Alan Çözümleri</th>
                <th scope="col" class="table-success text-center">Kalgan</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">WANNACRY 2017</th>
                <td class="feature-icon">
                    <i class="fas fa-times-circle text-danger"></i>
                </td>
                <td class="feature-icon">
                    <i class="fas fa-times-circle text-danger"></i>
                </td>
                <td class="table-success feature-icon">
                    <i class="fas fa-check-circle text-success"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">PETYA 2017</th>
                <td class="feature-icon">
                    <i class="fas fa-times-circle text-danger"></i>
                </td>
                <td class="feature-icon">
                    <i class="fas fa-times-circle text-danger"></i>
                </td>
                <td class="table-success feature-icon">
                    <i class="fas fa-check-circle text-success"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">NOTPETYA 2017</th>
                <td class="feature-icon">
                    <i class="fas fa-times-circle text-danger"></i>
                </td>
                <td class="table-success feature-icon">
                    <i class="fas fa-check-circle text-success"></i>
                </td>
                <td class="table-success feature-icon">
                    <i class="fas fa-check-circle text-success"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">GANDCRAB 2018</th>
                <td class="feature-icon">
                    <i class="fas fa-times-circle text-danger"></i>
                </td>
                <td class="feature-icon">
                    <i class="fas fa-times-circle text-danger"></i>
                </td>
                <td class="table-success feature-icon">
                    <i class="fas fa-check-circle text-success"></i>
                </td>
              </tr>
              <tr>
                <th scope="row">COBALT GROUP KAMPANYALARI 2018</th>
                <td class="feature-icon">
                    <i class="fas fa-times-circle text-danger"></i>
                </td>
                <td class="feature-icon">
                    <i class="fas fa-times-circle text-danger"></i>
                </td>
                <td class="table-success feature-icon">
                    <i class="fas fa-check-circle text-success"></i>
                </td>
              </tr>
            </tbody>
          </table>
    </div>
    <p>
        Neden? Antivirüs, sürekli gelişen sıfır gün saldırılarını tespit etmek için donanımlı değildir. Antivirüs laboratuvarları günler, haftalar veya aylar içinde yeni kötü amaçlı yazılımları tanımlar ve bu tanımlama gerçekleşene kadar güncellemeler yayınlanmaz. Bu boşluk, kötü amaçlı yazılımların bir kurumsal ağlarda hasara yol açması için yeterli süredir. Sanal alan teknolojisi, özel koşullar altında gerçekleştirilen sıfır gün veya bölünmüş saldırıları tanımlayamaz. Sanal alan çözümünden kaçınma, siber suçlular arasında sürekli geliştirilmekte olan bilinen bir tekniktir, çünkü fidye yazılımı büyük bir iş ağı oluşturmaktadır.
    </p>
    <div class="featured-box">
        <span class="content-featured-title">Kalgan CDR çözümü, kötü amaçlı yazılım girişinin tüm noktalarını kapsayacak şekilde uygulama esnekliği sağlar.</span>
        <span class="featured-content">
            Kalgan, e-posta, FTP veya USB sürücüler gibi çıkarılabilir ortamlar üzerinden paylaşılan, aktarılan ve hareketsiz dosyaları kapsayan hem şirket içi hem de bulut tabanlı çözümler sunar. Dosya türleri, ilke ayarlarıyla da idari olarak yönetilebilir ve böylece kurumsal ağın korunan ortamında hangi dosyalara izin verildiğini kontrol etmek için hassas bir denetim düzeyi sağlanır.
        </span>
    </div>
    <span class="page-title">
        KALGAN&#174; Ürünleri
    </span>
    <div class="k-products row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fab fa-chromecast"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174; Kiosk</h5>
                  <p class="card-text">Uzaktaki çıkarılabilir ortamları temizler</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fas fa-envelope"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174;<br/>E-Posta Tarayıcı</h5>
                  <p class="card-text">E-posta eklerini temizler</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fas fa-network-wired"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174; NetFolder</h5>
                  <p class="card-text">Aktarılan dosyaları temizler</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fas fa-shield-alt"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174; Kasa</h5>
                  <p class="card-text">CyberArk güvenli eklentisi</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fas fa-terminal"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174; API</h5>
                  <p class="card-text">Herhangi bir dosya işleme uygulaması için uygulama çözümü</p>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fab fa-usb"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174; İstemci</h5>
                  <p class="card-text">USB dosya temizleme için masaüstü uygulaması</p>
                </div>
            </div>
        </div>

    </div>
    <span class="page-title">
        KALGAN&#174; Hizmetleri
    </span>
    <div class="k-products services row">
        <div class="col-lg-3 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fas fa-cubes"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174; CDR Hizmeti</h5>
                  <p class="card-text">Gömülü dosya temizleme hizmeti</p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fas fa-cloud"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174; Kiosk Hizmeti</h5>
                  <p class="card-text">Bulut tabanlı kiosk yönetim hizmeti</p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fas fa-mail-bulk"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174; O365 Posta Eklentisi<sup>BETA</sup></h5>
                  <p class="card-text">E-posta ekleri temizleme yerel uygulaması</p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 item">
            <div class="card">
                <span class="card-icon">
                    <i class="fas fa-plug"></i>
                </span>
                <div class="card-body">
                  <h5 class="card-title">KALGAN&#174; Tarayıcı Uzantısı<sup>BETA</sup></h5>
                  <p class="card-text">Chrome, Explorer ve Firefox için CDR eklentisi</p>
                </div>
            </div>
        </div>
        
    </div>

</div>
</div>