<div class="content-base">
<div class="page-content">
    <span class="page-title">
        KALGAN&#174; Nedir?
    </span>
    <p class="lead">
        Algılama teknolojisine dayalı geleneksel çözümlerin aksine, İçerik Çözme ve Yeniden Yapılandırma (CDR), dosyaları tarama ve olası kötü amaçlı kodları etkisiz hale getirmeyi kapsayan önleyici bir süreçtir.
    </p>
    <div class="row">
        <div class="col-md-8">
            <p>
                CDR, dosya yapısının ikili düzeyde geçerliliğini doğrulamaya odaklanır ve hem bilinen hem de bilinmeyen tehditleri devre dışı bırakır. Bu, tehditleri tarama, bir kötü amaçlı yazılım alt kümesini algılama ve dosyaları engelleme süreçlerini izleyen virüsten koruma ve sanal alan yöntemlerinden çok farklıdır. 
            </p>
            <p>
                CDR ile sıfır gün de dahil olmak üzere tüm kötü amaçlı yazılımlar engellenir ve kullanıcı orijinal olarak etkilenen  dosyanın güvenli bir kopyasını alır. Kalgan CDR teknolojisi artık algılama gerektirmeyen, devrim niteliğinde bir yaklaşım getiriyor. Kalgan ile dosyalar, özel biçimlerine göre güvenli bir şekilde parçalarına bölünüp yeniden oluşturulur. “Yeni” dosyalar orijinal işlevlerini korur ve kullanımları güvenlidir. Sonuç, kuruluşlar için sistem performansından veya iş süreçlerinden ödün vermeden önemli ölçüde geliştirilmiş bilgi güvenliği sağlamasıdır.
            </p>
        </div>
        <div class="col-md-4">
            <span class="page-creative">
                <img src="/assets/img/gfx-about-1.png" />
            </span>
        </div>
        <div class="col-md-4">
            <span class="page-creative left-side-img">
                <img src="/assets/img/gfx-about-2.png" />
            </span>
        </div>
        <div class="col-md-8">
            <span class="content-title">Fidye Yazılımları Nedir? Zararları Nelerdir?</span>
            <p class="lead">
                Fidye yazılımı, Siber Suç'un en yaygın biçimlerinden biri haline geldi. Masum görünümlü bir dosya içerisinde gizlenen kötü amaçlı yazılımla başlar; açıldığında kötü amaçlı kod yürütür ve fidye ödemesi yapılana kadar tüm sisteminizi rehin tutar.
            </p>
            <p>
                Fidye yazılımı saldırılarını önlemenin tek yolu, yazılımın ilk etapta şirket ağına sızmasını önlemektir. İlk bulaşmadan sonra, fidye yazılımı paylaşılan depolama sürücüleri ve diğer erişilebilir bilgisayarlar da dahil olmak üzere bağlı sistemlere felaket etkisi yaratabilecek ölçüde yayılmaya çalışacaktır. Fidye yazılımı operasyonlarında baskın aktörlerden biri olan GandCrab Fidye Yazılımı saldırısının(2018) analizi, bize saldırı yönteminin basit olduğunu öğretir. "Ödenmemiş Fatura #NNNNNNN" veya "Sipariş #NNNNN" başlıklı spam posta, bir VBS komut dosyası veya Javascript sıkıştırılmış zip arşivi içerir. Arşivlerin içine yerleştirilmiş gizlenmiş komut dosyaları, saldırganların yaygın güvenlik çözümleriyle algılanmasını engeller. Çoğu şirket için sorun şudur: Mevcut kötü amaçlı yazılımdan koruma çözümleri, ilk karşılaşmada yeni kötü amaçlı yazılımların çoğunu algılayamaz.
            </p>
            
        </div>
        <div class="col-md-8">
            <p>
                Florida Eyaleti Riviera Beach Belediyesi bunu zor yoldan öğrendi. Mayıs 2019'da, Riviera Beach Polis Departmanı bir siber saldırıya maruz kaldı ve sistemlerini zamanında kurtarmak için şehrin fidye olarak 600.000 dolar ödemesiyle sonuçlandı.

            </p>
            <p>
                CDR ile sıfır gün de dahil olmak üzere tüm kötü amaçlı yazılımlar engellenir ve kullanıcı orijinal olarak etkilenen  dosyanın güvenli bir kopyasını alır. Kalgan CDR teknolojisi artık algılama gerektirmeyen, devrim niteliğinde bir yaklaşım getiriyor. Kalgan ile dosyalar, özel biçimlerine göre güvenli bir şekilde parçalarına bölünüp yeniden oluşturulur. “Yeni” dosyalar orijinal işlevlerini korur ve kullanımları güvenlidir. Sonuç, kuruluşlar için sistem performansından veya iş süreçlerinden ödün vermeden önemli ölçüde geliştirilmiş bilgi güvenliği sağlamasıdır.
            </p>
        </div>
        <div class="col-md-4">
            <span class="page-creative">
                <img src="/assets/img/gfx-about-3.png" />
            </span>
        </div>
    </div>

    
    
</div>
</div>