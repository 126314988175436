<div class="content-base">
<div class="k-jumbotron">
    <span class="title">
        KALGAN
        <small>Güncel Etkili Fidye Yazılımı Önleme Sistemi</small>
    </span>
    <span class="text">
        Algılama teknolojisine dayalı geleneksel çözümlerin aksine, İçerik Çözme ve Yeniden Yapılandırma (CDR),
        dosyaları tarama ve olası kötü amaçlı kodları etkisiz hale getirmeyi kapsayan önleyici bir süreçtir.
    </span>
</div>
<a routerLink="/about" class="scrollToMainBtn">
    <i class="fas fa-chevron-right"></i>
</a>
</div>