<div class="content-base">
<div class="page-content">
    <span class="page-title">
        Bizimle İletişime Geçin
    </span>
    <div class="row">
        <div class="col-lg-7 col-md-12">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit($event)" [ngClass]="{'d-none': mailSended || mailError}">
        
                <div class="form-group input-group-lg">
                    <label>Adınız Soyadınız: <sup class="text-danger">*</sup>
                    </label>
                    <input class="form-control" placeholder="Adınız Soyadınız" formControlName="name" name="name">
                </div>

                <div class="form-group input-group-lg">
                    <label>İletişime Geçeceğimiz E-Posta Adresiniz: <sup class="text-danger">*</sup>
                    </label>
                    <input class="form-control" placeholder="E-Posta Adresiniz" formControlName="email" name="email">
                </div>

                <div class="form-group input-group-lg">
                    <label>Konu: <sup class="text-danger">*</sup>
                    </label>
                    
                    <select formControlName="subject" class="form-control" (change)="checkTheVal()" name="subject">
                        <option value="">Lütfen Seçiniz</option>
                        <option *ngFor="let subject of Subjects">{{subject}}</option>
                    </select>
                </div>

                <div class="form-group input-group-lg"  [ngClass]="{'d-none': !demoStatus}">
                    <label>Erişim Yapılacak IP Adresiniz: <sup class="text-danger">*</sup>
                    </label>
                    <input class="form-control" placeholder="0.0.0.0" formControlName="ipaddress" name="ipaddress">
                </div>

                <div class="form-group input-group-lg">
                    <label>Mesajınız: <sup class="text-danger">*</sup>
                    </label>
                    <textarea class="form-control" name="message" placeholder="Bize yazmak istediğiniz detaylar" required formControlName="message" rows="6" name="message"></textarea>
                </div>
        
                <div class="form-group">
                    <button class="btn btn-danger btn-block btn-lg" [disabled]="!contactForm.valid">Gönder <i class="far fa-paper-plane"></i></button>
                </div>
            </form>
            <div class="feedback-area" [ngClass]="{'show': mailSended}">
                <span class="feedback-icon green">
                    <i class="far fa-check-circle"></i>
                </span>
                <span class="feedback-text">
                    Talebiniz tarafımızdan başarı ile alınmıştır. Size en kısa zamanda dönüş yapılacaktır.
                    <br/><br/>
                    İlginize teşekkür ederiz...
                </span>
            </div>
            <div class="feedback-area" [ngClass]="{'show': mailError}">
                <span class="feedback-icon red">
                    <i class="fas fa-ban"></i>
                </span>
                <span class="feedback-text">
                    İşlem yapılırken bir hata oluştu.
                    <br/><br/>
                    Lütfen daha sonra tekrar deneyiniz
                    <p>
                        <b>Hata Mesajı:</b><br/>
                        {{mailResultText}}
                    </p>
                </span>
            </div>
        </div>
        <div class="col-lg-5 col-md-12">
            <span class="company-logo">
                <a href="http://www.bilisimkupu.com" target="_blank"><img src="/assets/img/bk-logo.png" title="Bilişim Küpü"/></a>
            </span>
            <address>Üniversiteler Mahallesi 1596. Cad.<br> Hacettepe Teknokent<br>6. Ar-Ge Binası E Blok Kat:3 No:20<br>06800, Beytepe, Çankaya / ANKARA</address>
            <span class="company-infos">
                <b><i class="fas fa-at"></i> <a href="mailto:info@bilisimkupu.com?subject='Kalgan'">info@bilisimkupu.com</a></b>
                <b><i class="fas fa-phone-volume"></i> +90 312 504 23 32</b>
                <b><i class="fas fa-mouse-pointer"></i> <a href="http://www.bilisimkupu.com" target="_blank">www.bilisimkupu.com</a></b>
            </span>
            
        </div>
    </div>
</div>
</div>