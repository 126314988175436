<div class="k-bg-vid" id="bgVid"></div>
<div class="k-vid-overlay"></div>
<div class="container-fluid k-contents">
  <div class="k-header">
    <a routerLink="/" class="logo"></a>
    <span class="menu" id="systemMenu">
      <a routerLink="/about" routerLinkActive="activated" class="item">
        Kalgan Nedir?
      </a>
      <a routerLink="/features" routerLinkActive="activated" class="item">
        Neler Yapabilir?
      </a>
      <a routerLink="/contact" class="btn btn-primary">
        Denemek İstiyorum
      </a>
    </span>
  </div>
  <router-outlet></router-outlet>

</div>
<div class="mobile-menu-btn" id="mobileBtn">
  <button class="hamburger hamburger--collapse" type="button" (click)="toggleMenu()">
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>  
</div>
  
