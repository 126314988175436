import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

declare function  buildContactForm(): any;
declare function  clearContactForm(): any;
declare const  changeBgVideo: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'
]
})
export class ContactComponent implements OnInit {
  Subjects: any = ['Demo İsteği', 'Bilgi İsteği', 'Soru', 'Diğer']
  demoStatus: boolean = false;
  contactForm: FormGroup;
  mailSended:boolean = false;
  mailError:boolean = false;
  mailResultText:any;
  constructor(public fb: FormBuilder, private http: HttpClient) {
    this.contactForm = this.fb.group({
      name: ['', [
        Validators.required,
        Validators.minLength(6)
      ]],
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      ipaddress: ['', [
        Validators.required,
        Validators.pattern('^\\d+(\\.\\d+)*$')
      ]],
      subject: ['', Validators.required],
      message: ['', [
        Validators.required,
        Validators.minLength(10)
      ]]
    })
  }

  ngOnInit(): void {
    changeBgVideo();
  }

  checkTheVal(){
    if(this.contactForm.value.subject == "Demo İsteği"){
      this.demoStatus = true;
      this.contactForm.patchValue({ipaddress:''});
    }else{
      this.demoStatus = false;
      this.contactForm.patchValue({ipaddress:'0.0.0.0'});
    }
    this.contactForm.updateValueAndValidity();
  }

  onSubmit(e: Event){
    e.preventDefault();
    console.log(e.target);
    emailjs.sendForm('yandex', 'kalganweb', e.target as HTMLFormElement, 'user_RaDu25IYl9pTpiHAyxoJi')
      .then((result: EmailJSResponseStatus) => {
        console.log(result);
        if(result.status == 200){
          this.mailSended = true;
          this.mailError = false;
        }else{
          this.mailSended = false;
          this.mailError = true;
          this.mailResultText = result.text;
        }
      }, (error) => {
        this.mailSended = false;
        this.mailError = true;
        this.mailResultText = error.text;
      });
    
  }


}
